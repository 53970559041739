<template>
  <b-card-code no-body title="Cash Adjustment Transaction">

    <div class="dt_adv_search ml-1 mb-1 mr-1">
        <div class="row">

          <div class="col-12" v-if="checkPermission('add deduct cash user')">
            <b-button v-b-modal.form-cash class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add/Withdraw Cash">
              <plus-circle-icon size="1x" class="custom-class"></plus-circle-icon> Add Transaction
            </b-button>

            <b-modal no-close-on-backdrop id="form-cash" title="Add Adjustment Transaction">
              <div class="form">

                <div class="form-group">
                  <label for="">Type:</label>
                  <select class="form-control" v-model="formPayload.type">
                    <option value="add">Add</option>
                    <option value="deduct">Withdraw</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">User:</label>
                  <v-select
                      v-model="formPayload.user_id"
                      :options="users"
                      :reduce="option => option.id"
                      @search="onSearchUser"
                    >
                      <template slot="no-options">
                        Type user name, code, or email..
                      </template>
                    </v-select>
                </div>

                <div class="form-group">
                  <label for="">Amount:</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">{{ formPayload.currency_code }}</span>
                    </div>
                    <input type="number" class="form-control" v-model="formPayload.amount">
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Note: (Optional)</label>
                  <textarea class="form-control" v-model="formPayload.note"></textarea>
                </div>

              </div>

              <template #modal-footer="{}">
                <div v-if="isLoading">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <b-button variant="success" @click="addDeductCash()" v-bind:disabled="isLoading">
                  Submit
                </b-button>
              </template>
            </b-modal>
          </div>

          <div class="col-12">
            <div class="form-row mb-1">
                <div class="col-lg-4">
                    <label>Filter by User</label>
                    <v-select
                      v-model="filter.user_id"
                      :options="users"
                      :reduce="option => option.id"
                      @search="onSearchUser"
                    >
                      <template slot="no-options">
                        Type user name, code, or email..
                      </template>
                    </v-select>
                </div>
            </div>
          </div>

        </div>
    </div>

    <cash-transaction-table :user_id="filter.user_id" :is_adjustment="1" :hide_type="true" :hide_bg="true" v-if="showTable"></cash-transaction-table>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import vSelect from 'vue-select'
import { PlusCircleIcon } from 'vue-feather-icons'
import CashTransactionTable from '../components/CashTransactionTable.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  title () {
    return `Cash Adjustment Transaction History`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    vSelect,
    PlusCircleIcon,
    CashTransactionTable,
    BSpinner,
    flatPickr
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      filter: {
        user_id: '',
      },
      showTable: true,
      users: [],
      isLoading: false,
      formPayload: {
        type: 'add',
        user_id: null,
        currency_code: 'USD',
        amount: 0,
        note: '',
        is_collateral: false,
        is_adjustment: true,
        active_until: null,
      },
    }
  },
  created() {
    this.executeSearch('')
  },
  methods: {
    
    executeSearch(query){
      var vm = this
      this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + escape(query))
      .then(response => {
        vm.users = [];
        response.data.data.data.forEach(function(user){
          vm.users.push({
            'id': user.id,
            'label': user.code + ' (' + user.name + ')'
          })
        });

      })
    },
    onSearchUser(search, loading) {
      if(search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(escape(search))
      loading(false);
    }, 300),
    addDeductCash() {
      this.formPayload.is_collateral = this.formPayload.is_collateral ? 1 : 0;
      if (this.formPayload.is_collateral == 0) {
        this.formPayload.active_until = null
      }

      if (this.formPayload.is_collateral == 1 && (this.formPayload.active_until == null || this.formPayload.active_until == '')) {
        alert('Expire date is required for Bank Guarantee');
      } else {

        this.isLoading = true;

        this.$http.post('/admin/cash/add-transactions', this.formPayload)
        .then(response => {
  
          this.reloadTable();
          this.$bvModal.hide('form-cash')
          successNotification(this, 'Success', 'Transaction successfully created')
  
          this.cleanupForm();
          this.isLoading = false;
  
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)   
          }
          this.isLoading = false;
        })

      }
    },
    reloadTable() {
      this.showTable = false;
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
    cleanupForm() {
      this.formPayload = {
        type: 'add',
        user_id: null,
        currency_code: 'USD',
        amount: 0,
        note: '',
        active_until: null,
        is_adjustment: true,
      }
    },
  }
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>